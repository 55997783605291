import { Check } from "lucide-react";
import { Button } from "@nextui-org/react";
import { json, type LoaderFunctionArgs, type MetaFunction } from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";
import { authenticator } from "~/services/auth.server";
import NavBar from "~/components/Layouts/NavBar";
import { prisma } from "~/db.server";
import * as m from "$paraglide/messages";

export const meta: MetaFunction = () => [{ title: "Projects" }];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const user = await authenticator.isAuthenticated(request);

  if (!user) {
    return json({
      user: null,
      projects: [] as { id: number; title: string }[],
    });
  }

  const companyId = user.currentCompany.id;

  const memberships = await prisma.projectMembership.findMany({
    where: {
      userId: user?.id,
      project: {
        companyId,
      },
    },
    select: {
      role: true,
      project: {
        select: {
          id: true,
          title: true,
        },
      },
    },
  });

  const projects = memberships.map((membership) => ({ ...membership.project, role: membership.role }));

  return json({ user, projects });
};

export default function Index() {
  const { user, projects } = useLoaderData<typeof loader>();

  return (
    <div className="min-h-screen bg-white text-black font-sans">
      <NavBar user={user} projects={projects} sticky={true} />

      {/* Section 1: Hero */}
      <section className="container mx-auto px-4 py-16 md:py-32 flex flex-col items-center justify-center text-center">
        <h1 className="text-4xl md:text-5xl mb-6 font-bold">
          {m.witty_calm_octopus_whisper()}
        </h1>
        <p className="text-lg md:text-xl mb-10 max-w-2xl text-gray-700">
          {m.sleepy_brave_panda_dance()}
        </p>
        <Button
          as={Link}
          size="lg"
          to="./signup"
          color="primary"
          radius="full"
          className="bg-gradient-to-tr from-warning to-danger text-white shadow-lg"
        >
          {m.curious_swift_gazelle_leap()}
        </Button>
      </section>

      {/* Section 2: Features */}
      <section className="bg-[#f6f0e6] py-20">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row md:items-stretch">
            <div className="md:w-1/3 mb-8 md:mb-0 md:pr-24 flex items-center justify-center md:justify-start">
              <h2 className="text-3xl md:text-4xl font-bold text-center leading-tight tracking-normal">
                {m.eager_bright_flamingo_expect()}
              </h2>
            </div>
            <div className="md:w-2/3 space-y-8">
              <div className="flex items-start text-left">
                <Check className="mr-4 text-success mt-1 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-normal mb-1 text-gray-800">{m.gentle_wise_owl_blink()}</h3>
                  <p className="text-gray-700">{m.lively_kind_dolphin_splash()}</p>
                </div>
              </div>
              <div className="flex items-start text-left">
                <Check className="mr-4 text-success mt-1 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-normal mb-1 text-gray-800">{m.clever_quiet_fox_sneak()}</h3>
                  <p className="text-gray-700">{m.happy_fluffy_bunny_hop()}</p>
                </div>
              </div>
              <div className="flex items-start text-left">
                <Check className="mr-4 text-success mt-1 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-normal mb-1 text-gray-800">{m.brave_steady_turtle_crawl()}</h3>
                  <p className="text-gray-700">{m.playful_agile_monkey_swing()}</p>
                </div>
              </div>
              <div className="flex items-start text-left">
                <Check className="mr-4 text-success mt-1 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-normal mb-1 text-gray-800">{m.swift_graceful_deer_prance()}</h3>
                  <p className="text-gray-700">{m.patient_wise_elephant_trumpet()}</p>
                </div>
              </div>
              <div className="flex items-start text-left">
                <Check className="mr-4 text-success mt-1 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-normal mb-1 text-gray-800">{m.loyal_strong_wolf_howl()}</h3>
                  <p className="text-gray-700">{m.curious_nimble_squirrel_scurry()}</p>
                </div>
              </div>
              <div className="flex items-start text-left">
                <Check className="mr-4 text-success mt-1 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-normal mb-1 text-gray-800">{m.elegant_calm_swan_glide()}</h3>
                  <p className="text-gray-700">{m.friendly_playful_otter_swim()}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 3: Testimonials */}
      <section className="container mx-auto px-4 py-20">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-black leading-tight tracking-normal">
          {m.curious_happy_parrot_speak()}
        </h2>
        <div className="flex flex-col lg:flex-row justify-between space-y-6 lg:space-y-0 lg:space-x-6 max-w-6xl mx-auto">
          <blockquote className="flex-1 flex flex-col text-sm md:text-base italic text-gray-700 border-l-4 border-[#38ac94] p-6 bg-gray-50 rounded-r shadow-md">
            <p className="flex-grow mb-4">{m.majestic_wise_eagle_soar()}</p>
            <footer className="text-right text-xs md:text-sm text-gray-500">
              <span className="font-semibold text-success">Mathilde C.</span>
              <br />
              <span className="text-gray-400">{m.clever_calm_penguin_design()}</span>
            </footer>
          </blockquote>

          <blockquote className="flex-1 flex flex-col text-sm md:text-base italic text-gray-700 border-l-4 border-[#f39220] p-6 bg-gray-50 rounded-r shadow-md">
            <p className="flex-grow mb-4">{m.eloquent_gentle_butterfly_flutter()}</p>
            <footer className="text-right text-xs md:text-sm text-gray-500">
              <span className="font-semibold text-[#f39220]">Jean-Pierre L.</span>
              <br />
              <span className="text-gray-400">{m.witty_brave_lion_create()}</span>
            </footer>
          </blockquote>

          <blockquote className="flex-1 flex flex-col text-sm md:text-base italic text-gray-700 border-l-4 border-[#e01e25] p-6 bg-gray-50 rounded-r shadow-md">
            <p className="flex-grow mb-4">{m.brilliant_serene_hummingbird_hover()}</p>
            <footer className="text-right text-xs md:text-sm text-gray-500">
              <span className="font-semibold text-[#e01e25]">Sophie M.</span>
              <br />
              <span className="text-gray-400">{m.gentle_wise_owl_decorate()}</span>
            </footer>
          </blockquote>
        </div>
      </section>
    </div>
  );
}
